<template>
  <div :class="scaleClass" :style="style">
    <h5 :class="`scale-type scale-${scaleType}`">{{ scaleType }} Scale</h5>
    <h3 class="scale-title">{{ this.colorMapName }}</h3>
    <h3 v-if="this.plotSubtitle !== null" class="scale-subtitle mt-2">{{ this.plotSubtitle }}</h3>
    <div class="scale-bg">
      <div class="grad-box">
        <span v-for="(value, index) in annotationStrings"
        :key="index"
        :class="gradClass(index)"
        :style="{ left: annotationPercentages[index] + '%' }"><span class="grad-label">{{ value }}</span></span>
      </div>
    </div>
    <h4 class="scale-units">{{ this.plotDescription }}
      <a v-if="this.dataInterpretationURL" :href="this.dataInterpretationURL" target="_blank" class="data-help-button"></a>
    </h4>
          <b-button  
          v-b-tooltip.hover title="export colorscale" 
          v-if="!downloadingAssest" 
          @click="downloadColorScale()" 
          variant="outline" 
          class="p-0 mt-0" style="position: absolute; bottom: 0.1rem; right:0.4rem;">
            <b-icon icon='download' class="text-white" font-scale=0.9></b-icon>
          </b-button>
  </div>
</template>

<script>
import ColorScaleData from '@/utilities/color-scales/color';
import PlotType from '@/utilities/color-scales/plot-type';
import html2canvas from 'html2canvas';

export default {
  name: 'ColorScale',
  props: {
    scaleType: {
      type: String,
      required: true
    },
    colorMap: {
      required: true
    }
  },
  computed: {
    colorScaleData() {
      return new ColorScaleData(this.plotType, this.gradientPoints, this.colorMap?.Points);
    },
    colorMapName() {
      return this.colorMap?.TitleOverride || this.colorMap?.Name;
    },
    plotSubtitle() {
      return this.colorMap?.PlotSubtitle;
    },
    plotDescription() {
      return this.colorMap?.PlotDescription;
    },
    dataInterpretationURL() {
      return this.colorMap?.DataInterpretation;
    },
    plotType() {
      return this.colorMap?.IndexedColors ? PlotType?.DISCRETE : PlotType?.CONTINUOUS;
    },
    gradientPoints() {
      return this.colorMap?.IndexedColors || this.colorMap?.RGBPoints;
    },
    style() {
      return { '--linear-gradient': this.colorScaleData.linearGradientString };
    },
    annotationStrings() {
      return this.colorMap?.Annotations.filter(annotation => typeof annotation === 'string');
    },
    annotationPercentages() {
      return this.colorScaleData.cssAnnotationPercentages(this.colorMap?.Annotations);
    },
    scaleClass() {
      return this.plotType === PlotType?.DISCRETE ? 'discrete-scale scale' : 'scale';
    },
  },
  methods: {
    //TODO: remove the download button after button clicked
    async downloadColorScale(){
      this.downloadingAssest = true;
      await new Promise((resolve) => {
        const element = document.getElementsByClassName('popup-container colour-scale-menu');
        resolve(html2canvas(element[0], {allowTaint: true,  useCORS: true }));
      }).then(canvas => {
        document.body.appendChild(canvas);  
        const colorScaleUrl = canvas.toDataURL('image/png');
        let aTag = document.createElement('a');
        aTag.href = colorScaleUrl; 
        aTag.download = 'colorscale.png'; 
        aTag.click(); 
        document.body.removeChild(canvas);  
      });
      this.downloadingAssest = false;
    },
    gradClass(index) {
      if (index === 0) {
        return 'grad min';
      }
      if (index >= this.annotationStrings.length - 1) {
        return 'grad max';
      }
      return 'grad';
    },
  },
  data(){
    return{
      downloadingAssest: false, 
    };
  }
};
</script>

<style scoped>
.data-help-button {
  margin-left: 0.313rem;
  margin-top: -0.063rem;
  content: "";
  background: url('~@/assets/svg/data-interpretation-help.svg') no-repeat;
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
}

.scale {
  width: calc(100% - 2rem);
  text-align: center;
  margin: 0 auto;
  padding: 3rem 0.75rem 1rem;
  min-width: 18rem;
  max-width: 24rem;
  background-color: rgba(144,150,151,.5);
  border-radius: 0.313rem;
  position: relative;
}

.scale + .scale {
  margin-top: 1rem;
}

.scale-type {
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  margin: 0;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.scale-type:before {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
}

.scale-horizontal:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC42OTMiIGhlaWdodD0iOS40ODEiIHZpZXdCb3g9IjAgMCAxMC42OTMgOS40ODEiPg0KICA8ZyBpZD0iR3JvdXBfMzI3NiIgZGF0YS1uYW1lPSJHcm91cCAzMjc2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMTguNjkyIC0xMDEzMC4wMTkpIj4NCiAgICA8cGF0aCBpZD0iUGF0aF8yNjY2IiBkYXRhLW5hbWU9IlBhdGggMjY2NiIgZD0iTS0xMDcuNTU5LTgxLjg4bDQuNjU1LDMuMTIyLDQuNjUtMy4xMjJoMEwtMTAyLjktODVsLTQuNjUxLDMuMTIzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0iI2E4YWNiMiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgIDxwYXRoIGlkPSJQYXRoXzI2NjYtMiIgZGF0YS1uYW1lPSJQYXRoIDI2NjYiIGQ9Ik0tMTA3LjU1Ny03OS42NDNsNC42NTUsMy4xMjIsNC42NS0zLjEyMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
  width: 1rem;
  height: 0.75rem;
  background-size: 1.5em;
  margin-right: 0.125rem;
  margin-bottom: -0.125rem;
}

.scale-vertical:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjY1NCIgaGVpZ2h0PSIxMC45NCIgdmlld0JveD0iMCAwIDcuNjU0IDEwLjk0Ij4NCiAgPGcgaWQ9Ikdyb3VwXzMyNzgiIGRhdGEtbmFtZT0iR3JvdXAgMzI3OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjE4LjE1NiAtMTAxNjEuMzMxKSI+DQogICAgPHBhdGggaWQ9IkxpbmVfNjUiIGRhdGEtbmFtZT0iTGluZSA2NSIgZD0iTS0xMDUuMjExLTQzLjc1bDQuNjUtMy4xMjNWLTUyLjVoMGwtNC42NSwzLjEyMmgwdjUuNjI0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0iI2E4YWNiMiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgIDxwYXRoIGlkPSJMaW5lXzY1LTIiIGRhdGEtbmFtZT0iTGluZSA2NSIgZD0iTS0xMDIuNTY1LTUzLjVsLTQuNjUsMy4xMjJoMHY1LjYyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
  width: 0.75rem;
  height: 1rem;
  background-size: 1.125em;
  margin-right: 0.25rem;
  margin-bottom: -0.25rem;
}

.scale-volumetric:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC42OTMiIGhlaWdodD0iMTIuODciIHZpZXdCb3g9IjAgMCAxMC42OTMgMTIuODciPg0KICA8ZyBpZD0iR3JvdXBfMzI4MCIgZGF0YS1uYW1lPSJHcm91cCAzMjgwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMTguNjc1IC0xMDE5OC42NzcpIj4NCiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzc1IiBkYXRhLW5hbWU9IkVsbGlwc2UgNzUiIGN4PSIyLjgxMiIgY3k9IjIuODEyIiByPSIyLjgxMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxNi4xMzkgMTAyMDIuMjk5KSIgZmlsbD0iI2E4YWNiMiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgIDxnIGlkPSJHcm91cF8zMjYwIiBkYXRhLW5hbWU9Ikdyb3VwIDMyNjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTAuNDQxIDEwMjE1LjUyMSkiPg0KICAgICAgPGcgaWQ9Ikdyb3VwXzEzMTkiIGRhdGEtbmFtZT0iR3JvdXAgMTMxOSI+DQogICAgICAgIDxwYXRoIGlkPSJQYXRoXzI2NjciIGRhdGEtbmFtZT0iUGF0aCAyNjY3IiBkPSJNLTEwMi44ODEtMTYuMzQ1bC00LjY1MSwzLjEyM1YtNy42bDQuNjUxLDMuMTIzLDQuNjUtMy4xMjN2LTUuNjI0WiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgICAgICA8cGF0aCBpZD0iUGF0aF8yNjY2IiBkYXRhLW5hbWU9IlBhdGggMjY2NiIgZD0iTS05OC4yMzUtMTMuMjIybC00LjY1LDMuMTIyLTQuNjU1LTMuMTIyIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgICAgIDxsaW5lIGlkPSJMaW5lXzY1IiBkYXRhLW5hbWU9IkxpbmUgNjUiIHkyPSI1LjYyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMi44ODMgLTEwLjEpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgICA8L2c+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==");
  width: 1rem;
  height: 1rem;
  background-size: 1.25em;
  margin-right: 0.125rem;
  margin-bottom: -0.4rem;
}

.scale-title {
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  margin: -1.5rem 0 0 0;
}

.scale-subtitle {
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.scale-type + .scale-title {
  margin: -1.25rem 0 0 0;
}

.scale-units {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  margin: -2rem 0 0 0;
}

.scale-bg {
  width: calc(100% - 4rem);
  height: 1.25rem;
  border: 0.125rem solid #fff;
  margin: 2.75rem auto;
  background: var(--linear-gradient);
}

.scale.discrete-scale .scale-bg {
  width: 100%;
  margin: 2.75rem 0;
}

.grad-box {
  margin: -2.125rem 0 .75rem 0;
  position: relative;
}

.grad-box > * {
  margin: 0;
}

.grad {
  position: absolute;
  padding: 0 0 0.25rem 0;
  font-size: 0.7rem;
}

.grad-label {
  position: relative;
  margin-left: -65%;
  display: block;
  font-size: 0.8em;
}

.grad.min {
  margin-left: -0.125rem;
}

.grad.max {
  margin-right: 0;
}

.grad:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 0.125rem;
  height: 1rem;
  background-color: #fff;
}

/* Discrete Scale styles */

.discrete-scale .grad:after {
  display: none; /* omit the ticks */
}

.discrete-scale .grad-box {
  margin: -1.5rem 0 .75rem 0; /* move the labels closer to the scale */
  position: relative;
}

.discrete-scale .grad {
  position: absolute;
  padding: 0;
  font-size: 0.7rem;
  transform: translate(-50%); /* align the label to the middle of the element */
}

.discrete-scale .grad-label {
  position: relative;
  margin-left: 0;
  display: block;
  text-align: center;
}
</style>